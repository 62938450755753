<template>
  <div class="addHousing">
    <div class="addHousing_box w-1400 margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页 </el-breadcrumb-item>
          <el-breadcrumb-item>添加房源</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="addHousing_head">
        <div class="addHousing_head_main b-c-f">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>基本信息</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <el-form
            :model="from"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
          >
            <ul class="addHousing_head_ul flex flex-wrap">
              <li>
                <el-form-item label="类型:" prop="fang_type">
                  <el-select
                    v-model="from.fang_type"
                    placeholder="请选择房源类型"
                  >
                    <el-option
                      v-for="item in getZuHousingTypeList()"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房源等级:" prop="fang_level">
                  <el-select
                    v-model="from.fang_level"
                    placeholder="请选择房源等级"
                  >
                    <el-option
                      v-for="item in zuGradeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房源状态:" prop="fang_status">
                  <el-select
                    v-model="from.fang_status"
                    placeholder="请选择房源状态"
                  >
                    <el-option
                      v-for="item in fangStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="小区名称:" prop="xiaoqu_id">
                  <el-select
                    @change="changePlot($event, 1)"
                    v-model="from.xiaoqu_id"
                    filterable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in plotList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="楼栋号:">
                  <div class="addHousing_head_ul_ipt building flexs">
                    <el-input
                      v-model="from.lou"
                      placeholder="几号楼"
                      type="number"
                    ></el-input>
                    <el-input
                      v-model="from.dan"
                      placeholder="几单元"
                      type="number"
                    ></el-input>
                    <el-input
                      v-model="from.hao"
                      placeholder="请输入"
                      type="number"
                    ></el-input>
                    <el-input
                      v-model="from.orientation"
                      placeholder="朝向"
                    ></el-input>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="楼层:">
                  <div class="addHousing_head_ul_ipt building flexs floor">
                    <el-input
                      v-model="from.xiaoqu_louceng"
                      maxlength="3"
                      type="number"
                      placeholder="小区楼层"
                    ></el-input>
                    <el-input
                      v-model="from.zong_louceng"
                      maxlength="3"
                      type="number"
                      placeholder="小区总楼层"
                    ></el-input>
                    <el-select
                      v-model="from.louceng_type"
                      placeholder="楼层区域"
                    >
                      <el-option
                        v-for="item in loucengTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房型:">
                  <el-select
                    v-model="from.fang_xing_copy"
                    placeholder="请输入房型(室)"
                  >
                    <el-option
                      v-for="item in roomList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="朝向:">
                  <el-select
                    v-model="from.fang_chaoxiang"
                    placeholder="请输入朝向"
                  >
                    <el-option
                      v-for="item in orientationList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="面积:">
                  <el-input
                    v-model="from.fang_mianji"
                    placeholder="请输入面积"
                  ></el-input>
                </el-form-item>
              </li>
              <li v-if="from.xiaoqu_id">
                <el-form-item label="梯户:">
                  <el-select v-model="from.fang_tihu" placeholder="请选择梯户">
                    <el-option
                      v-for="item in tiHuList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="租金:">
                  <el-input
                    v-model="from.can_price"
                    placeholder="请输入租金"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="付款方式:">
                  <el-select
                    v-model="from.price_type"
                    placeholder="请选择付款方式"
                  >
                    <el-option
                      v-for="item in payList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="租期:">
                  <el-select v-model="from.zu_time" placeholder="请选择租期">
                    <el-option
                      v-for="item in rentTime"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="租赁方式:">
                  <el-select
                    v-model="from.zu_type"
                    placeholder="请选择租赁方式"
                  >
                    <el-option
                      v-for="item in rentList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="from.zu_type">
                <el-form-item label="出租时长:">
                  <el-select
                    v-model="from.zu_types"
                    placeholder="请选择出租时长"
                  >
                    <el-option
                      v-for="item in rentStateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="到期时间:">
                  <el-date-picker
                    v-model="from.daoqi_times"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="是否到期:">
                  <el-select v-model="from.is_qi" placeholder="请选择是否到期">
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="能否看房:">
                  <el-select v-model="from.is_kan" placeholder="请选择能否看房">
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房屋用途:">
                  <el-select
                    v-model="from.fang_yongtu"
                    placeholder="请选择房屋用途"
                  >
                    <el-option
                      v-for="item in houseUse"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房龄:">
                  <el-select v-model="from.fang_ling" placeholder="请选择房龄">
                    <el-option
                      v-for="item in houseAgeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房屋性质:">
                  <el-select
                    v-model="from.fang_gou"
                    placeholder="请选择房屋性质"
                  >
                    <el-option
                      v-for="item in buildingList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="装修:">
                  <el-select v-model="from.fang_xiu" placeholder="请选择装修">
                    <el-option
                      v-for="item in fitmentList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="装修时间:">
                  <el-select
                    v-model="from.fang_xiu_time"
                    placeholder="请选择装修时间"
                  >
                    <el-option
                      v-for="item in fitmentTimeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="看房方式:">
                  <el-select
                    v-model="from.fang_kan"
                    placeholder="请选择看房方式"
                  >
                    <el-option
                      v-for="item in lookWayList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="最早入住时间:">
                  <el-select
                    v-model="from.fang_zhu"
                    placeholder="请选择最早入住时间"
                  >
                    <el-option
                      v-for="item in checkTimeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="钥匙所在店铺:">
                  <el-select
                    v-model="from.yaoshi_dian"
                    placeholder="请选择钥匙所在店铺"
                  >
                    <el-option
                      v-for="item in menDianList"
                      :key="item.id"
                      :label="item.dian_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="钥匙情况:">
                  <el-select
                    v-model="from.yaoshi_type"
                    placeholder="请选择钥匙情况"
                  >
                    <el-option
                      v-for="item in keyCondition"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="from.yaoshi_type == '密码'">
                <el-form-item label="密码:">
                  <el-input
                    v-model="from.yaoshi_password"
                    placeholder="请输入密码"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="车位:">
                  <el-select
                    v-model="from.che_que"
                    placeholder="请选择是否有车位"
                  >
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="可带小孩:">
                  <el-select
                    v-model="from.du_hai"
                    placeholder="请选择是否可带小孩"
                  >
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="可带宠物:">
                  <el-select
                    v-model="from.du_pets"
                    placeholder="请选择是否可带宠物"
                  >
                    <el-option
                      v-for="item in whetherList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="业主姓名:">
                  <el-input
                    v-model="from.yezhu_name"
                    placeholder="请输入业主姓名"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="业主电话:">
                  <div class="owner-phone">
                    <div
                      class="owner-phone-li"
                      v-for="(item, index) in phoneList"
                      :key="index"
                    >
                      <el-input
                        maxlength="11"
                        v-model="item.phone"
                        placeholder="请输入业主电话"
                      ></el-input>
                      <div @click="addPhone(index)">
                        <img
                          v-if="index == 0"
                          class="estate-floor-add-icon"
                          src="../../assets/image/personage/add1.png"
                        />
                        <img
                          v-else
                          class="estate-floor-add-icon"
                          src="../../assets/image/personage/dele.png"
                        />
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="产权人:">
                  <el-input
                    v-model="from.fang_chanquan"
                    placeholder="请输入产权人"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="产权人证件类型:">
                  <el-select
                    v-model="from.fang_chanquan_type"
                    placeholder="请选择产权人证件类型"
                  >
                    <el-option
                      v-for="item in certificateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="证件号:">
                  <el-input
                    v-model="from.fang_chanquan_type_hao"
                    placeholder="请输入证件号"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="权属证书类型:">
                  <el-select
                    v-model="from.fang_quanshu_type"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in certificateTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="权属证书号码:">
                  <el-input
                    v-model="from.fang_quanshu_type_hao"
                    placeholder="请输入权属证书号码"
                  ></el-input>
                </el-form-item>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">房产证图片:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in fang_chancheng_images"
                    :key="index"
                  >
                    <img :src="item" alt="" class="avatar" />
                    <img
                      @click="cancelUpload('fang_chancheng_images', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'fang_chancheng_images')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">
                  身份证反正面图片:
                </div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in identity_images"
                    :key="index"
                  >
                    <img :src="item" alt="" class="avatar" />
                    <img
                      @click="cancelUpload('identity_images', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'identity_images')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">委托书图片:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in weituo_images"
                    :key="index"
                  >
                    <img :src="item" alt="" class="avatar" />
                    <img
                      @click="cancelUpload('weituo_images', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'weituo_images')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
              <li>
                <el-form-item label="房屋标签:">
                  <div class="houseing_ul flexs flex-wrap">
                    <div
                      class="houseing_ul_li center"
                      @click="changeTag(item)"
                      :class="{ active: item.checked }"
                      v-for="(item, index) in houseTagList"
                      :key="index"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="列表标签:">
                  <div class="houseing_ul flexs flex-wrap">
                    <div
                      class="houseing_ul_li center"
                      @click="item.checked = !item.checked"
                      :class="{ active: item.checked }"
                      v-for="(item, index) in houseTagsList"
                      :key="index"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房源展示:" prop="fang_type_wang">
                  <el-select
                    v-model="from.fang_type_wang"
                    placeholder="请选择房源展示"
                  >
                    <el-option
                      v-for="item in netList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="维护人:" prop="weihu_id">
                  <el-select filterable
                    remote
                    :remote-method="getxius" v-model="from.weihu_id" placeholder="请选择维护人">
                    <el-option
                      v-for="item in userList"
                      :key="item.user_id"
                      :label="item.name"
                      :value="item.user_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="核验布置:">
                  <el-select v-model="from.img_bu" placeholder="请选择核验布置">
                    <el-option
                      v-for="item in imageLayout"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="业主费用:">
                  <el-select
                    v-model="from.yezhu_prc"
                    placeholder="请选择业主费用"
                  >
                    <el-option
                      v-for="item in ownerPrice"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
            </ul>
          </el-form>
          <div class="houseSelect m-t-30">
            <div class="houseSelect_txt m-b-20">户型选择</div>
            <ul class="houseSelect_ul flex flex-wrap">
              <li
                @click="selectHuxing(item)"
                v-for="(item, index) in houseSelectList"
                :key="index"
              >
                <div class="houseSelect_ul_select">
                  <img
                    src="../../assets/image/houseing/select_pink.png"
                    v-if="item.checked"
                    alt=""
                  />
                  <img
                    src="../../assets/image/houseing/select.png"
                    alt=""
                    v-else
                  />
                </div>
                <div class="houseSelect_img">
                  <img :src="item.image" alt="" />
                </div>
                <div class="houseSelect_mian">
                  <div class="houseSelect_name">{{ item.huxing }}</div>
                  <div class="houseSelect_area">
                    面积{{ item.mianji }}m²/{{ item.chaoxiang }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="houseSelect m-t-30">
            <div class="houseSelect_txt m-b-30 m-t-20">房源简介</div>
            <div class="facility">
              <div class="">内部设施:</div>
              <ul>
                <li @click="item.checked = !item.checked" :class="{active:item.checked}" v-for="(item) in facilityList" :key="item.type" class="center">{{ item.name }}</li>
              </ul>
            </div>
            <div class="houseSelect_rich">
              <div id="rich"></div>
            </div>
          </div>
          <div class="addHousing_head_main_btn center">
            <el-button @click="addHousing('ruleForm')">保存</el-button>
          </div>
        </div>
        <!-- v-if="houseId" -->
        <div class="addHousing_head_main m-t-20 b-c-f" v-if="houseId">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>图片</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <ul class="addHousing_head_ul flex flex-wrap">
            <ul
              class="addHousing_head_ul addHousing_head_img addHousing_head_uls"
            >
              <li>
                <div class="addHousing_head_ul_txt m-r-20">封面图:</div>
                <div class="addHousing_head_img_ul">
                  <el-upload
                    class="avatar-uploaders"
                    :show-file-list="false"
                    action="https://xin.xinyoujudichan.com/api/common/upload"
                    list-type="picture-card"
                    :on-success="uploadCoverImg"
                  >
                    <img
                      v-if="from.fang_fengmian_image"
                      :src="from.fang_fengmian_image"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">轮播图:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <el-upload
                    multiple
                    class=""
                    action="https://xin.xinyoujudichan.com/api/common/upload"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreview"
                    :on-remove="handleRemove"
                  >
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <!-- <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in fang_lunbo_images"
                    :key="index"
                  >
                    <img :src="item" alt="" class="avatar" />
                    <img
                      @click="cancelUpload('fang_lunbo_images', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'fang_lunbo_images')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div> -->
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">视频:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div
                    class="addHousing_head_img_ul_li avatar-uploader m-r-20"
                    v-for="(item, index) in fang_vediofile"
                    :key="index"
                  >
                    <video :src="item"></video>
                    <img
                      @click="cancelUpload('fang_vediofile', index)"
                      src="../../assets/image/publice/cancel.png"
                      class="close"
                      alt=""
                    />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input
                      type="file"
                      @input="uploadVideoImg($event, 'fang_vediofile')"
                    />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
          <div class="addHousing_head_main_btn center">
            <el-button @click="saveImg">保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import index from "../../common/housing";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "region"]),
  },
  data() {
    return {
      houseId: null, //房源id
      regionList: regionData,
      CodeToText: CodeToText,
      value: "",
      input: "",
      type: null, //1出售2出租
      areaList: [], //区域列表
      from: {
        fang_type: "", //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        fang_level: "", //房源等级 A/B/C/D/展位
        fang_status: 1, //1未交易3有效暂缓5无效暂缓7我司成交9他司成交
        xiaoqu_id: "", //小区id
        xiaoqu_loudong: "", //小区楼栋号 （用逗号隔开）
        xiaoqu_louceng: "", //小区楼层
        zong_louceng: "", //小区总楼层
        lou: "", //几号楼
        dan: "", //几单元
        hao: "", //几号
        orientation: "", //朝向
        basement:"",
        louceng_type: "", //楼层区域（低/中/高/顶）
        fang_xing_copy: "", //房型（室 直接传数字）
        fang_chaoxiang: "", //朝向
        fang_mianji: "", //面积（平方）
        fang_tihu: "", //梯户
        can_price: "", //参考价
        price_type: "", //付款方式
        zu_time: "", //租期
        zu_type: "", //租赁方式
        zu_types: "", //租赁状态
        zong_price: "", //总价
        fang_yongtu: "", //房屋用途 居住/办公/仓库
        fang_ling: "", //房龄
        fang_gou: "",
        fang_xing: "1",
        fang_xiu: "", //装修 毛坯/简单装修/居家装修/精装修/豪华装修
        fang_xiu_times: "", //装修时间
        fang_xiu_time: "", //装修时间 时间戳
        fang_kaipan_time: "", //开盘时间 输入数字
        fang_kaipan_ci: "", //开盘次数 首开/加推
        fang_kan: "", //看房方式 我司有钥匙/借钥匙/业主开门
        fang_zhu: "", //最早入住时间 输入时间戳
        yaoshi_dian: "", //钥匙所在店铺id
        du_hai: "", //是否可带小孩
        du_pets: "", //是否可带宠物
        yezhu_name: "", //业主姓名
        yezhu_mobile: "", //业主电话
        fang_type_wang: "", //内外网房源 内网/外网
        fang_chanquan: "", //产权人
        fang_chanquan_type: "", //产权人证件 身份证/房产证
        fang_chanquan_type_hao: "", //产权人证件号
        fang_quanshu_type: "", //权属证书
        fang_quanshu_type_hao: "", //权属证书号
        fang_chancheng_images: "", //房产证图（多张用逗号隔开）,
        identity_images: "", //身份证图
        weituo_images: "", //委托书图
        fang_biaoqian: "", //房屋标签（多个用逗号隔开）
        fang_huxing: "", //户型id
        fang_content: "", //房源简介
        fang_jingwei: "", //经纬度
        fang_fengmian_image: "",
        che_que: "", //是否有车位
        img_bu: "", //核验布置
        yezhu_prc: "", //业主费用
        yaoshi_type: "", //钥匙情况
        yaoshi_password: "", //密码
        is_kan: "",
        is_qi: "",
        daoqi_times: "",
        ji: "",
        sun: "",
        ches: "",
        jing: "",
        ding: "",
        pin: "",
        cheng: "",
        dianshi:'',
        bingx:'',
        xiyiji:'',
        kongtiao:'',
        reshuiqi:'',
        wifi:'',
        yigui:'',
        nuanqi:'',
        chuang:'',
        ranqi:''
      },
      rules: {
        fang_type: [
          { required: true, message: "请选择房源类型", trigger: "blur" },
        ],
        fang_level: [
          { required: true, message: "请选择房源等级", trigger: "change" },
        ],
        fang_status: [
          { required: true, message: "请选择房源状态", trigger: "change" },
        ],
        xiaoqu_id: [
          { required: true, message: "请选择小区名称", trigger: "change" },
        ],
        fang_type_wang: [
          { required: true, message: "请选择房源展示", trigger: "change" },
        ],
        weihu_id: [
          { required: true, message: "请选择维护人", trigger: "change" },
        ],
        yezhu_mobile: [
          { required: true, message: "请输入业主电话", trigger: "blur" },
        ],
      },
      fang_chancheng_images: [], //房产证图片
      identity_images: [], //身份证
      weituo_images: [], //委托书图片
      fang_fengmian_image: [], //封面图
      fang_lunbo_images: [], //轮播图
      fang_vediofile: [], //视频
      rentTime: [],
      tiHuList: [], //梯户列表
      userList: [], //
      phoneList: [
        {
          name: "",
          phone: "",
        },
      ],
      houseTagsList: [
        {
          name: "急",
          checked: false,
          type: "ji",
        },
        {
          name: "笋",
          checked: false,
          type: "sun",
        },
        {
          name: "车",
          checked: false,
          type: "ches",
        },
        {
          name: "精",
          checked: false,
          type: "jing",
        },
        {
          name: "顶",
          checked: false,
          type: "ding",
        },
        {
          name: "频",
          checked: false,
          type: "pin",
        },
        {
          name: "诚",
          checked: false,
          type: "cheng",
        },
      ], //
      facilityList:[
        {
          name: "电视",
          checked: false,
          type: "dianshi"
        },
        {
          name: "冰箱",
          checked: false,
          type: "bingx"
        },
        {
          name: "洗衣机",
          checked: false,
          type: "xiyiji"
        },
        {
          name: "空调",
          checked: false,
          type: "kongtiao"
        },
        {
          name: "热水器",
          checked: false,
          type: "reshuiqi"
        },
        {
          name: "WiFi",
          checked: false,
          type: "wifi"
        },
        {
          name: "衣柜",
          checked: false,
          type: "yigui"
        },
        {
          name: "暖气",
          checked: false,
          type: "nuanqi"
        },
        {
          name: "床",
          checked: false,
          type: "chuang"
        },
        {
          name: "天然气",
          checked: false,
          type: "ranqi"
        }
      ],//内部设施
    };
  },
  methods: {
    handlePictureCardPreview(e) {
      this.fang_lunbo_images.push(e.data.fullurl);
    },
    handleRemove(e) {
      let data = [];
      this.fang_lunbo_images.forEach((item) => {
        if (item != e.response.data.fullurl) data.push(item);
      });
      this.fang_lunbo_images = data;
    },
    //选择小区
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = this.getFTPrivilegeXiaoQuList(res.data.data);
        });
    },
    selectHuxing(item) {
      item.checked = !item.checked;
      this.$forceUpdate();
    },
    //保存图片
    saveImg() {
      // if (!this.from.fang_fengmian_image)
      //   return this.$message({ message: "请上传封面图", type: "warning" });
      // if (this.fang_lunbo_images.length == 0)
      //   return this.$message({ message: "请上传轮播图", type: "warning" });

      this.$axios[this.type == 1 ? "houseShouAddImages" : "houseZuAddImages"]({
        id: this.houseId,
        fang_fengmian_image: this.from.fang_fengmian_image,
        fang_lunbo_images: this.fang_lunbo_images.length
          ? this.fang_lunbo_images.join(",")
          : "",
        fang_vediofile: this.fang_vediofile.length
          ? this.fang_vediofile.join(",")
          : "",
      }).then((res) => {
        this.$message({ message: res.msg, type: "success" });
        this.$router.go(-1);
      });
    },
    //添加出售房源
    addHousing(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            !this.phoneList.every((item) =>
              item.phone
            )
          )
            return this.$message({
              message: "请输入业主电话",
              type: "warning",
            });
          let phones = [];
          this.phoneList.forEach((item) => {
            phones.push(item.phone);
          });
          this.from.yezhu_mobile = phones.join(",");

          this.from.xiaoqu_loudong =
            this.from.lou +
            (this.from.dan ? "," + this.from.dan : "") +
            (this.from.hao ? "," + this.from.hao : "") +
            (this.from.orientation ? "," + this.from.orientation : "");

          if (this.fang_chancheng_images.length) {
            this.from.fang_chancheng_images =
              this.fang_chancheng_images.join(",");
          }
          if (this.identity_images.length) {
            this.from.identity_images = this.identity_images.join(",");
          }
          if (this.weituo_images.length) {
            this.from.weituo_images = this.weituo_images.join(",");
          }
          this.from.basement = this.from.louceng_type
          // let location = this.$store.state.location;
          this.from.fang_content = this.editor.txt.html();
          // this.from.fang_jingwei = location.lng + "," + location.lat;
          let huxing = [];
          this.houseSelectList.forEach((item) => {
            if (item.checked) {
              huxing.push(item.id);
            }
          });
          this.from.fang_huxing = huxing.join(",");

          this.houseTagsList.forEach((em) => {
            this.from[em.type] = em.checked ? em.name : "";
          });

          this.facilityList.forEach((em) => {
            this.from[em.type] = em.checked ? 1 : 0;
          });

          // console.log({...this.from})
          // return

          this.$axios[this.type == 1 ? "houseShouAdd" : "houseZuAdd"](this.from)
            .then((res) => {
              this.houseId = res.data;
              this.$message({ message: res.msg, type: "success" });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    changeHead(value) {
      this.from.fang_type = value;
    },
    getxius(e) {
      this.$axios
        .userList({
          name:e,
          position: 4,
        })
        .then((res) => {
          this.userList = res.data;
        });
    },
    //添加电话号码
    addPhone (index) {
      index == 0 ? this.phoneList.push({name:'',phone:''}) : this.phoneList.splice(index,1)
    }
  },
  created() {
    this.getxius('');
    this.type = this.$route.query.type;
    // if (this.type == 2) this.from.fang_type = 2;
    let data = [];
    for (let index = 0; index < 60; index++) {
      data.push({
        value: index + 1,
        label: `${index + 1}个月`,
      });
    }
    let houseAgeList = [];

    for (let i = 0; i <= 100; i++) {
      houseAgeList.push({
        value: i,
        label: `${i}年`,
      });
    }
    this.houseAgeList = houseAgeList;
    this.rentTime = data;
  },
};
</script>

<style lang="less" scoped>
.addHousing_head_list {
  padding: 0 225px;
  background: #3273f6;
  li {
    cursor: pointer;
    height: 64px;
    color: #ebebeb;
    font-size: 18px;
  }
  .active {
    color: #ffffff;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 24px;
      height: 2px;
      background: #ffffff;
    }
  }
}
.addHousing_head_title {
  padding-left: 100px;
  span {
    &:first-child {
      font-size: 18px;
      padding-left: 14px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 20px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: #3273f6;
        position: absolute;
      }
    }
    &:last-child {
      color: #3273f6;
      font-size: 16px;
    }
  }
}
.addHousing_head_ul {
  padding: 0 50px;
  li {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .addHousing_head_ul_txt {
      width: 140px;
      color: #666666;
      font-size: 16px;
      text-align: right;
    }
    /deep/ .el-input__inner {
      width: 400px;
      height: 40px;
      border: 1px solid #cccccc;
    }

    .building {
      /deep/ .el-input__inner {
        width: 96px;
        margin-right: 5px;
      }
    }
    .floor {
      /deep/ .el-input__inner {
        width: 130px;
      }
    }
    .time {
      /deep/ .el-input {
        width: 400px !important;
      }
    }
    .houseing_ul_li {
      height: 28px;
      color: #666666;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;
      background: #ebebeb;
      border-radius: 3px;
      margin: 0 10px 10px 0;
    }
    .active {
      color: #3273f6;
      background: #e1ebff;
    }
  }
}
.addHousing_head_uls {
  padding: 0;
  li {
    width: 100%;
    .addHousing_head_ul_txt {
      width: 60px !important;
    }
  }
}
.addHousing_head_img,
.addHousing_head_ul {
  align-items: flex-start;
  li {
    align-items: flex-start;
    .addHousing_head_img_ul {
      /deep/ .el-upload-list__item {
        width: 116px;
        height: 116px;
      }
      .addHousing_head_img_ul_li {
        position: relative;
        margin-bottom: 10px;
        .close {
          top: -12px;
          right: -12px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: absolute;
        }
        input {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          cursor: pointer;
          position: absolute;
          border-radius: 6px;
        }
      }
    }
  }
}
.required {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.houseSelect {
  padding: 0 100px;
  .facility {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: #666666;
    font-size: 16px;
    ul {
      display: flex;
      margin-left: 20px;
      li {
        cursor: pointer;
        padding: 0 10px;
        height: 28px;
        font-size: 14px;
        margin-right: 10px;
        background: #EBEBEB;
        border-radius: 3px;
      }
      .active {
        color:#3273F6;
        background: #E1EBFF;
      }
    }
  }
  .houseSelect_txt {
    // width: 140px;
    color: #3273f6;
    font-size: 16px;
    // text-align: right;
  }
  .houseSelect_ul {
    li {
      display: flex;
      width: 580px;
      padding: 20px;
      margin-bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
      border-radius: 4px;
      position: relative;
      .houseSelect_ul_select {
        top: 20px;
        right: 20px;
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .houseSelect_img {
        img {
          width: 120px;
          height: 90px;
        }
        margin-right: 10px;
      }
      .houseSelect_mian {
        height: 90px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
      .houseSelect_name {
        font-size: 20px;
      }
      .houseSelect_area {
        color: #666666;
        font-size: 18px;
      }
    }
  }
  .houseSelect_rich {
    background: #fafafa;
    border-radius: 8px;
    padding: 20px;
  }
}
.addHousing_head_main_btn {
  padding: 60px 0;
  /deep/ .el-button {
    width: 680px;
    height: 56px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 8px;
  }
}
/deep/ .el-form-item {
  margin-bottom: 0;
}
.fitment_time {
  /deep/ .el-input {
    width: 400px;
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
// /deep/ input[type="number"] {
//   -moz-appearance: textfield !important;
// }
.avatar-uploaders /deep/ .el-upload,
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 118px;
  height: 118px;
  video {
    width: 100%;
    height: 100%;
    display: block;
  }
}
/deep/ .el-upload--picture-card {
  width: 118px;
  height: 118px;
}
.avatar-uploader /deep/ .el-upload:hover,
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 116px;
  height: 116px;
  line-height: 116px;
  text-align: center;
  display: block;
  background-color: #fbfdff;
}
.avatar {
  width: 116px;
  height: 116px;
  display: block;
}
</style>
